@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap);
:root{
  --themeclr: #005eb8;
  --cardclr:#1d1d1d;
}
body {
  margin: 0;
  font-family: 'poppins' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #111;
  color:#fff !important;
  font-size: 15px !important;

}
body.lightmodal {
  background-color: #fff !important;
}
#root.lighttheme{
  background-color: #fff !important;
}
.flexHeader{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;
}
.lighttheme .msg{
  color:#000
}
header, main, footer {
  padding-left: 300px;
}
.list-group-item-action:hover, .list-group-item-action:focus{
  z-index: auto !important;
}
@media screen and (min-width:768px){

}
@media(max-width:600px){

  .cust-chat .msg , .cust-chat .reply {
    max-width: calc(100% - 50px) !important;
  }
}
@media only screen and (max-width : 992px) {
  header, main, footer {
    padding-left: 0;
  }
}

.list-group-flush .list-group-item
{
  background-color:#000;
  color:#fff !important;
  border: 1px solid rgb(149 144 144 / 13%);
  font-weight: 500 !important;
}
.list-group-flush .list-group-item:hover,.list-group-flush .list-group-item.active
{
  color:#fff !important;
  background-color: #005eb8 !important;
  background-color: var(--themeclr) !important;
  border-color: #005eb8 !important;
  border-color: var(--themeclr) !important;
  z-index: 0;
}
.border-right
{
  border-color: #0e0e0e !important;
  border-right: 1px solid #0e0e0e !important;
}
.card 
{
  background: transparent !important;
  padding: 20px;
  border: 1px solid #005eb8 !important;
  border: 1px solid var(--themeclr) !important;
  border-radius: 5px !important;
  /* box-shadow: 0 0 20px 1px #dbe2e92e; */
  max-width: 450px !important;
  margin-left: auto;
  margin-right: auto;
}
.card.formBox 
{
  background: #005eb8 !important;
  background: var(--themeclr) !important;
  padding: 40px;
  border-radius: 5px !important;
  box-shadow: 0 0 20px 1px #dbe2e92e;
  max-width: 450px !important;
  margin-left: auto;
  margin-right: auto;
}
.form_header
{
  color: #76b7e9 !important;
  font-size: 25px !important;
  font-weight: 600;
}
.text-primary,h2.text-primary
{
  color:#fff !important;
}
h2.text-primary
{
  font-size: 22px !important;
  font-weight: 600;
}
.container input
{
  background-color: #03081f;
    border: 1px solid #81c8f6;
    color: #fff !important;
    min-height: 52px !important;
    border-radius: 5px !important;
    border: none !important;
}
label{
  font-size: 14px !important;
    display: block;
    width: 100%;
    color: #c1c2ca !important;
    margin-bottom: 10px;
}
.btn-primary
{
  background-color: #fff !important;
  /* background-image: linear-gradient(180deg,#ffffff 20%, #b3b3b3 50%); */
    background-color: #5087c0;
    color: #000 !important  ;
    border-radius: 25px !important;
    padding: 7px 20px !important;
    border: 1px solid #5087c0 !important;
    font-size: 13px !important;
    font-weight: 500 !important;
    transition: .2s all;
    box-shadow: 0 0 9px 1px #2765a7;
    margin-left: auto;
    margin-right: auto;
    transition: all 0.3s ease-in-out;
    width: auto;
    cursor: pointer;
    display: block;
    outline: none;
    box-shadow: 0 0 9px 1px #2765a7 !important;
}
.btn-primary:hover
{
  background: transparent !important;
    color: #81c8f6 !important;
    border-color: #81c8f6 !important;
}
.font_15
{
  font-size: 15px !important;
}
.link_blue_new
{
  color: #81c8f6;
    font-size: 15px !important;
    transition: .5s;
}
.link_blue_new:hover
{
  color: #ffff !important;
}
.flex_center
{
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
}
@media only screen and (max-width:575px)
{
  .table_filter
  {
    margin-top: 20px !important;
  }
  .table-head .text-right,.table-head .col-md-6
  {
    text-align: center !important;
  }
  .table-foot .col-md-6,.table-foot .col-md-6.text-right
  {
    margin-bottom: 20px !important;
    text-align: center !important;
  }
  .table-foot .col-md-6.text-right
  {
    float: unset !important;
  }
  .table-foot .col-md-6.text-right .pull-right
  {
    float: unset !important;
  }
  .table-foot .col-md-6.text-right .pull-right .justify-content-end
  {
    justify-content: center !important;
  }
  #page-content-wrapper h3
  {
    font-size: 22px !important;
  }
  .flex_center .card
  {
      min-width: 290px !important
  }
}
@media only screen and (min-width:576px)
{
  .flex_center .card
  {
      min-width: 380px !important
  }
}
.text-danger
{
  font-size: 14px !important;
}
.bg-dark.adminNav
{
    background-color: #02071d !important;
    z-index: 9;
}
h3.text-secondary
{
  color: #fcfcfc !important;
  font-size: 30px !important;
  font-weight: 600;
  margin-bottom: 20px !important;
}
.border-right,div.border-right {
  border-right: 1px solid #212224 !important;
}
.btn-light
{
  background: #5087c0 !important;
    background-image: linear-gradient(180deg,#48a7e1 20%, #5087c0 50%);
    color: #fff !important;
    border-radius: 25px !important;
    padding: 7px 20px !important;
    border: 1px solid #5087c0 !important;
    font-size: 13px !important;
    font-weight: 500 !important;
    transition: .2s all;
    box-shadow: 0 0 9px 1px #2765a7;
    margin-left: auto;
    margin-right: auto;
    transition: all 0.3s ease-in-out;
    width: auto;
    cursor: pointer;
    display: block;
    outline: none;
    box-shadow: 0 0 9px 1px #2765a7 !important;
}
.btn-light .fa
{
  color:#fff !important;
}
.btn-light svg
{
  color:#fff !important;
  fill:#fff !important;
}
.btn-light:hover
{
  background: transparent !important;
  color: #81c8f6 !important;
  border-color: #81c8f6 !important;
}
.btn-light:hover svg
{
  color: #81c8f6 !important;
}
.asrt-page-length .input-group-addon
{
  background: transparent !important;
  color:#fff !important;
}
.asrt-page-length .input-group-addon .input-group-text
{
  color:#fff !important;
}
#page-content-wrapper
{
  padding-bottom: 40px !important;
}
#as-react-datatable-container select
{
  background-color: #03081f;
    border: 1px solid #005eb8;
    color: #fff !important;
    min-height: 45px !important;
    border-radius: 5px !important;
}
.form-control,.form-control:focus,.form-control:focus-visible,.form-control:hover
{
  background-color: #000 !important;
  border: 1px solid #005eb8 !important;
    color: #fff !important;
    min-height: 52px !important;
    border-radius: 5px !important;
}
.as-react-table
{
  margin-top: 30px !important;
}
.btn-outline-primary,.btn-danger,.btn-secondary
{
  background: transparent !important;
    color: #fff !important;
    border-radius: 25px !important;
    padding: 7px 20px !important;
    border: 1px solid #005eb8 !important;
    font-size: 13px !important;
    font-weight: 500 !important;
    transition: .2s all;
    box-shadow: 0 0 9px 1px #2765a7 !important;
    margin-left: auto;
    margin-right: auto;
    transition: all 0.3s ease-in-out;
    width: auto;
    cursor: pointer;
    display: block;
    outline: none;
    box-shadow: 0 0 9px 1px #2765a7 !important;
}
.btn-outline-primary:hover,.btn-danger:hover,.btn-secondary:hover
{
  background: #5087c0 !important;
  background-image: linear-gradient(180deg,#48a7e1 20%, #5087c0 50%) !important;
}
.table thead th
{
  color: #fff !important;
    font-size: 15px;
    font-weight: 600;
  border-color:#141414 !important;

}
.table tbody td{
  border-bottom: 1px solid #141414 !important;
  color: #fff !important;
  border-color:#141414 !important;
  font-size: 14px !important;
  vertical-align: middle;
}
.table-bordered
{
  background: #000;
}
td.action button {
  margin-left: 0;
}
.darktheme .table-bordered {
  border: 1px solid #141414;
}
.page-item.disabled .page-link,.page-link
{
  background-color: transparent !important;
  border-color: #161c37 !important;
}
.page-link input,.page-link input:focus-visible
{
  background-color: transparent !important;
  color:#fff !important;
  border:none !important;
}
@media only screen and (max-width:991px)

{
  
  .navbar .navbar-collapse{
    position: absolute;
    right: 0px;
    background: #000;
    padding: 15px;
    top: 70px;
  }
  .lighttheme .navbar .navbar-collapse{
    position: absolute;
    right: 0px;
    background:#fff;
    color: #000;
    padding: 15px;
    top: 70px;
  }
  .sidebar_whole
  {
    position: fixed !important;
    left: 0px !important;
    top:63px !important;
    min-height: 100vh;
    width: 250px !important;
    z-index: 99 !important;
  }
}
.dropdown-menu
{
  background-color:  #000 !important;
}
.adminNav .dropdown-menu a
{
  color:#fff !important;
  text-decoration: none !important;
}
.adminNav .dropdown-menu a:hover
{
  color:#5087c0 !important;
}
.navbar-dark .navbar-nav .nav-link
{

  color:#fff !important;
}
.page-link
{
  color:#fff !important;
}
.page-link:hover
{
  color:#5087c0 !important;
}
.user_dt_table th:last-child,.user_dt_table td:last-child
{
min-width: 245px !important;
}
.pair_dt_table th:last-child,.pair_dt_table td:last-child
{
min-width: 145px !important;
}


.withdraw_dt_table th:nth-child(6),.withdraw_dt_table td:nth-child(6)
{
min-width: 200px !important;
}

.deposit_dt_table th:first-child,.deposit_dt_table td:first-child
{
  min-width: 180px !important;
}
.currency_dt_table th:last-child,.currency_dt_table td:last-child
{
  min-width: 150px !important;

}
.resonsive_table .table-body .col-md-12
{
    display: block;
    width: 100%;
    overflow-x: auto;
}
#page-content-wrapper
{
  padding-top: 80px !important;
  /* min-width: calc(100vw - 30px) !important; */
}
.navbar.adminNav
{
  position: fixed !important;
  width: 100% !important;
}




@media only screen and (min-width:992px)
{
    #page-content-wrapper {
        min-width: 0;
        width: calc(100% - 250px) !important;
        min-width: calc(100% - 250px) !important;

    margin-top: 1%;
    margin-left: 250px;
    }
    .shrink_whole #page-content-wrapper
    {
        width: 100% !important;
        margin-left: 0px !important;

    }

    .sidebar_whole
    {
      position: fixed !important;
      left: 0px !important;
      top:63px !important;
      min-height: 100vh;
      width: 250px !important;
      z-index: 99 !important;
    }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row;
      align-items: center;
  }
}

.modal-content
{
  background-color: #000 !important;
  box-shadow: 0 0 5px 1px #2929292e;
  border-radius: 10px !important;
}
.close
{
  color:#fff !important;
}
button:focus,.btn:focus
{
  outline: 0px auto -webkit-focus-ring-color !important;
}
.modal-title
{
  font-size: 22px !important;
}
.modal-header,.modal-footer
{
  border-color: #161c37 !important;

}
.modal
{
  padding-right: 0px !important;
}
.table-foot
{
  margin-top: 20px !important;
}
.border_blue_select
{
  border: none !important;
    background: transparent !important;
  /* border:none !important; */
  /* background: transparent !important; */
  /* color: #fff !important; */
 
}
input[type="file"] {
  display: none;
}
.custom-file-upload {
  background-color: transparent !important;
  border: 1px solid #005eb8 !important;
    color: #fff !important;
    min-height: 52px !important;
    border-radius: 5px !important;
    color:#fff !important;
  box-shadow: 0 0 0 0rem #fff !important;
  height: 52px !important;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 6px 12px;
  cursor: pointer;
}
.order_history_table th:last-child,.order_history_table td:last-child
{
  min-width: 180px !important;
}
.trade_history_table th:last-child,.trade_history_table td:last-child
{
  min-width: 180px !important;
}
.perpetual_table th:last-child,.perpetual_table td:last-child
{
  min-width: 150px !important;
}

.img_form
{
  max-width: 200px !important;
}
/* .btn-primary .fa
{
  color:#fff !important;
} */
.modal.show
{
  padding-left: 0px !important;
}

.perpetual_order_table th:first-child,.perpetual_order_table td:first-child
{
  min-width: 150px !important;
}

.perpetual_trade_table th:first-child,.perpetual_trade_table td:first-child
{
  min-width: 150px !important;
}
.liquidated_table th:last-child,.liquidated_table td:last-child
{
  min-width: 150px !important;
}
/* .tox .tox-edit-area__iframe
{
  background-color: transparent !important;
  color:#fff !important;
}
#notes_ifr,#notes_ifr p,.mce-content-body,.mce-content-body p,.tox-editor-container p,#tinymce,#tinymce p
{
  color:#fff !important;

}
.tox-edit-area
{
  filter: brightness(0) invert(1);
}
.tox .tox-toolbar-overlord,.tox .tox-toolbar__primary
{
  background-color: transparent !important;
}
.tox .tox-tbtn,.tox .tox-tbtn svg
{
  color:#fff !important;
  fill:#fff !important;

}
.tox .tox-tbtn--enabled, .tox .tox-tbtn--enabled:hover,.tox .tox-tbtn:hover
{
  background: #02071d !important;
  box-shadow: 0 0 0 0px #dee0e2 inset !important;
}
.tox.tox-tinymce-aux .tox-toolbar__overflow,.tox .tox-tbtn:focus
{
  background: #02071d !important;
  border-color:#81c8f6 !important;
}
.tox-tinymce,.tox:not([dir=rtl]) .tox-toolbar__group:not(:last-of-type)
{
  border-color:#81c8f6 !important;

}
.tox .tox-split-button:hover
{
  box-shadow: 0 0 0 0px #dee0e2 inset !important;

}
.tox .tox-tbtn--disabled svg, .tox .tox-tbtn--disabled:hover svg, .tox .tox-tbtn:disabled svg, .tox .tox-tbtn:disabled:hover svg {
  fill: #ccc !important;
}
.tox .tox-toolbar, .tox .tox-toolbar__overflow, .tox .tox-toolbar__primary
{
  background: transparent !important;
}
.tox:not([dir=rtl]) .tox-toolbar__group:not(:last-of-type)
{
  border-right: none !important;
}
.tox-tinymce
{
  border-radius: 10px !important;
}
.tox .tox-statusbar,.tox .tox-statusbar a, .tox .tox-statusbar__path-item, .tox .tox-statusbar__wordcount
{
  background: transparent !important;
  color:#FFF !important;

} */
.tox .tox-statusbar
{
  /* border-top: 1px solid #81c8f6 !important; */
  height: 40px !important;
}
/* #notes_ifr
{
  border-top:1px solid #81c8f6 !important;
}
.tox .tox-toolbar-overlord, .tox .tox-toolbar__primary
{
  background: transparent !important;

} */
/* .tox .tox-edit-area__iframe p,#tinymce,.mce-content-body,.tox .tox-edit-area__iframe body
{
  color:#fff !important;

} */
.btn-success
{
  background: #5087c0 !important;
    background-image: linear-gradient(180deg,#48a7e1 20%, #5087c0 50%);
    background-color: #5087c0 !important;
    color: #fff;
    border-radius: 25px !important;
    padding: 7px 20px !important;
    border: 1px solid #5087c0 !important;
    font-size: 13px !important;
    font-weight: 500 !important;
    transition: .2s all;
    box-shadow: 0 0 9px 1px #2765a7;
    margin-left: auto;
    margin-right: auto;
    transition: all 0.3s ease-in-out;
    width: auto;
    cursor: pointer;
    display: block;
    outline: none;
    box-shadow: 0 0 9px 1px #2765a7 !important;
}
.btn-success:hover
{
  background: transparent !important;
    color: #81c8f6 !important;
    border-color: #81c8f6 !important;
}

.copy_trade_table th:nth-child(3),.copy_trade_table td:nth-child(3)
{
  min-width: 150px !important;
}
.email_table th:nth-child(3),.email_table td:nth-child(3)
{
  min-width: 150px !important;
}
.email_table .btn-primary {display: inline;}
.cms_table th:last-child,.cms_table td:last-child
{
  min-width: 150px !important;
}

.faq_table th:last-child,.faq_table td:last-child
{
  min-width: 150px !important;
}

.contact_table th:last-child,.contact_table td:last-child
{
  min-width: 150px !important;
}
.contact_table th:nth-child(3),.contact_table td:nth-child(3)
{
  min-width: 150px !important;
}
.chip-body
{
background-color: #313131 !important;
}
.multiSelect input
{
  background-color: #02071d !important;
  border: 1px solid #005eb8 !important;
    color: #fff !important;
    min-height: 52px !important;
    border-radius: 5px !important;
}
.multiSelect .container
{
padding-left:30px !important;
}
.search-result
{
  border-left: 1px solid #81c8f6 !important;
    border-right: 1px solid #81c8f6 !important;
    border-bottom: 1px solid #81c8f6 !important;
    border-radius: 10px !important;
}

.support_table th:last-child,.support_table td:last-child
{
min-width: 150px !important;
}
.category_table th:last-child,.category_table td:last-child
{
min-width: 150px !important;
}
.article_table th:nth-child(2),.article_table td:nth-child(2)
{
min-width: 200px !important;
}
.help_table th:nth-child(2),.help_table td:nth-child(2)
{
min-width: 200px !important;
max-width: 200px !important;
}
.subcategory_table th:nth-child(2),.subcategory_table td:nth-child(2)
{
min-width: 200px !important;
}

.helpcentre_table th:nth-child(2),.helpcentre_table td:nth-child(2)
{
min-width: 200px !important;
}
.helpcentre_table th:nth-child(3),.helpcentre_table td:nth-child(3)
{
min-width: 200px !important;
}
.form-control:disabled, .form-control[readonly]
{
  background: #252525  !important;
}
.input_grp_btn
{
  background-color: #03081f !important;
    border: 1px solid #81c8f6 !important;
    color: #fff !important;
    min-height: 52px !important;
    border-radius: 10px !important;
}
.input_grp_btn input,.input_grp_btn input:hover,.input_grp_btn input:focus
{
  border: none !important;
  box-shadow: 0 0 0 0rem rgb(0 123 255 / 25%) !important;
}

.input_grp_btn .input-group-text
{
background-color: transparent !important;
border:none !important;
}




/* manoj */

.formBox {
  max-width: 450px !important;
  margin-left: auto;
  margin-right: auto;
}
.formBox {
  background: #005eb8;
  background: var(--themeclr);
  padding: 40px;
  border-radius: 15px;
  box-shadow: 0 0 20px 1px #dbe2e92e;
}
.btn-fill,.btn-primary,.btn-light{
  background: #005eb8 !important;
  background: var(--themeclr) !important;
  /* background-image: linear-gradient(180deg,#48a7e1 20%, #5087c0 50%); */
  color: #fff !important;
  border-radius: 5px !important;
  padding: 7px 20px !important;
  border: 1px solid #5087c000  !important;
  font-size: 13px !important;
  font-weight: 500 !important;
}
.btn-fill:hover,.btn-primary:hover,.btn-light:hover{
  background: transparent !important;
  border: 1px solid #5087c0 !important;
  color: #fff;
}
.darktheme .btn-primary:hover .fa,.darktheme .btn-light:hover .fa{
    color:#fff
}
.btn-primary {
    box-shadow: none !important;
}
.btn-outline{
  background: transparent !important;
  border: 1px solid #005eb8 !important;

  /* background-image: linear-gradient(180deg,#48a7e1 20%, #5087c0 50%); */
  color: #fff !important;
  border-radius: 5px !important;
  padding: 7px 20px !important;

  font-size: 13px !important;
  font-weight: 500 !important;
}
.btn-outline:hover{
  border: 1px solid #005eb8 !important;
  background: #005eb8 !important;
  background: var(--themeclr) !important;

  color: #fff;
}


.form-control:focus{
  box-shadow: unset !important;
}
.table tbody td.asrt-td-loading{
  background-color: #0b0b0b !important;
}
.fa-sun:before {
  content: "\f185";
  color: #000;
}

.fa-moon:before {
  content: "\f186";
  color: #fff;
}
button.transbtn.theme {
  border-color: transparent;
  background: transparent;
}
button.transbtn.theme:focus{
  outline: 0;
}
/* .navbar .navbar-brand img{
  width: 100%;
} */
.btn-primary{
  border-radius: 5px !important;
}
.darktheme .loginsec input {
    background: #005eb8 !important;
}
.wrapunset{
    flex-wrap: unset !important;
}
/* lighttheme */
body.lighttheme{
  background-color: #f2f2f2 !important;
}
.lighttheme h3.text-secondary {
  color: #005eb8 !important;}
.lighttheme .card-title, .lighttheme .card-text{
  color: #000;
}
.lighttheme .list-group-flush .list-group-item {
  background-color: #fff;
  color: #000 !important;}
  .lighttheme .bg-dark.adminNav {
    background-color: #fff !important;
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 20%);
}
.lighttheme .navbar-dark .navbar-nav .nav-link {
  color: #000 !important;
}
.lighttheme #menu-toggle {
  color: #000;
  border: 2px solid #000;
 
}
.lighttheme .form-control, .lighttheme .form-control:focus,.lighttheme .form-control:focus-visible, .lighttheme .form-control:hover {
  background-color: transparent !important;
  color: #000 !important;}

  .darktheme .form-control {
    background-color: transparent !important;
    color: #fff !important;
  }
  .lighttheme .form-control:focus-visible, 
  .lighttheme .form-control:hover {
      color: #000 !important;
  }

.lighttheme #as-react-datatable-container select{
  color: #000 !important;
}
.lighttheme .asrt-page-length .input-group-addon .input-group-text {
  color: #000 !important;
}
.lighttheme .btn-outline{
  color: #000 !important;
}
.lighttheme .table thead th {
  color: #000 !important;

}
.lighttheme .table-bordered {
  background: #ffffff !important;
  border-radius: 15px !important;
  box-shadow: 0 0 20px 1px #dbe2e92e;
}
.lighttheme .table tbody td {

  color: #000 !important;

}
.lighttheme #as-react-datatable.table thead, .lighttheme #as-react-datatable.table tbody td {
  background: #fff;
  color: #000 !important;
  border-color: #e2e2e2 !important;
}
.lighttheme #as-react-datatable.table thead th {

  border-top:none;
  border-color: #e7e7e7 !important;
  border-bottom: 1px solid #e7e7e7 !important;}

.lighttheme .table-foot.asrt-table-foot{
  color: #000 !important;
}
.lighttheme .page-item.disabled .page-link,.lighttheme  .page-link {

  color: #000 !important;
}
.lighttheme .page-link input,.lighttheme .page-link input:focus-visible{
  color: #000 !important;
}
.lighttheme .btn-fill:hover,.lighttheme .btn-primary:hover,.lighttheme .btn-light:hover,
.lighttheme .btn-primary:hover .fa,.lighttheme .btn-light:hover .fa {
  color: #000 !important;
}
.lighttheme .btn-outline:hover{
  color: #fff !important;
}
.lighttheme .modal-content{
  background-color: #fff !important;
}
.lighttheme .modal-title{
color:  #005eb8  !important;
}
.lighttheme label{
 color: #000 !important;
}
.lighttheme .modal-header .close{
  color: #000 !important;
}
.lighttheme .table tbody td.asrt-td-loading {
  background-color: #ffffff !important;
}
.lighttheme #update-contact{
  color: #000 !important;
}
.lighttheme .multiSelect input{
  background-color: transparent !important;
}
.lighttheme .chip-body{
color: #000 !important ;
background-color: #fff !important;
}
.lighttheme .card-header{
  color: #000 !important;
}


.lighttheme .list-group-flush .list-group-item:hover, .lighttheme .list-group-flush .list-group-item.active{
  color: #fff !important;
}
.lighttheme .input_grp_btn{
  background-color: transparent !important;
}
.lighttheme .dropdown-menu{
  background-color: #fff !important;

}
.lighttheme .adminNav .dropdown-menu a{
  color: #000 !important;
}
.lighttheme .adminNav .dropdown-menu a:hover{
 color: #005eb8 !important;
}

.lighttheme .css-1i8bkd6-ValueContainer{
  background: #fff !important;
  border-color: #005eb8 ;
  border-radius: 5px;
}
.darktheme .css-1i8bkd6-ValueContainer{
  background: #000 !important;
  border-color: #005eb8;
  border-radius: 5px;
}
.lighttheme .border_blue_select > div > div div{
  color:#000 !important
}
.darktheme .fa-sun:before{
  color: #fff !important;
}
.lighttheme .fa-moon:before{
  color: #000 !important;
}
.lighttheme .adminNav.navbar-dark .navbar-toggler {
  color: #000 !important;
  border: 2px solid #000 !important;}
  .darktheme .adminNav.navbar-dark .navbar-toggler {
    color: rgb(255, 255, 255) !important;
    border: 2px solid rgb(255, 255, 255) !important;}
  .lighttheme .formBox .form-control, .lighttheme .formBox .form-control:focus, .lighttheme  .formBox .form-control:focus-visible, .lighttheme .formBox .form-control:hover {
    background-color: white !important;
    color: #000 !important;
}

/* endlighttheme */


select option:hover {
background-color: #005eb8 !important;
color: #fff !important;
}

.input-group .input-group-append a {
    background: transparent !important;
    border-radius: 0 10px 10px 0;
    border-color: #5087c0 !important;
}
.input-group input,.input-group input:hover,
.input-group input:focus {
    border-radius: 10px 0 0 10px !important;
}
.darktheme .navbar-brand .lightthemeimg{
  display: none;
} 
.darktheme .tox-editor-container *, 
.darktheme .tox.tox-tinymce * {
    color: #fff!important;
    background: black !important;
    fill: #fff !important;
    border-color: #fff !important;
}
.lighttheme .navbar-brand .darkthemeimg{
  display: none;
} 
#as-react-datatable-container select option{
  color: #000 !important;
}
.input_grp_btn{
  border: 1px solid #5087c0   !important;
  background-color: transparent !important;
}
@media only screen and (max-width:991px)

{
  .dropdown-menu {
    background-color: #000000 !important;
}
}
.elipsis-225 {
  width: 225px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.lightmodal .withdrawdetails .modal-content{
  background-color: #fff !important;
  color: #000;
}
.lightmodal .withdrawdetails .modal-content label{
  color: #6f6f6f !important;
}
.lightmodal .modal-content{
  background-color: #fff !important;
  color: #000;
}
.lightmodal .close{
  color: #000 !important;
}
.multiSelect input{
  border: 1px solid #005eb8  !important;
  background-color: transparent !important;
}
.lighttheme .checkmark{
  background-color: #afafaf !important;
}
.modal-header, .modal-footer {
  border-color: #005eb8 !important;
}
.scrollbar_sidebar {
  width: 250px;
  max-height: calc(100vh - 65px);
  overflow-y: auto;
}
 .darktheme,.lighttheme, html, body,#root, #scrollbar_sidebar_id{
  scrollbar-width: thin;
  scrollbar-color: #0A4C95 #dcdcdc;
}
::-webkit-scrollbar-thumb {
  background-color: #005eb8;
}
::-webkit-scrollbar {
  width: 6px;
  height: 4px;
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px #dcdcdc;
}
td.action {
  /* display: flex; */
  margin: 0;
}
td.action {
  /* display: flex; */
}
.basic-multi-select > div {
  background: #fff !important;
  border-color: #005eb8;
  border-color: var(--themeclr);
}
.darktheme .basic-multi-select > div{
  background: #000 !important;
}
.basic-multi-select > div:hover {
  border-color: #005eb8;
  border-color: var(--themeclr);
}
.react-datepicker-wrapper{
  width: 100%;
}
.btn-info {
  background: transparent!important;
  border: 1px solid #005eb8!important;
  color: #005eb8!important;
  text-transform: capitalize!important;
  font-weight: 600!important;
  box-shadow: none!important;
  transition: .5s;
  font-size: 14px!important;
}

.select__control,.basic-multi-select > div{
  min-height: 52px;
}
td.action button:last-child{
  margin-left: 0;
  margin-right: auto;
}
td.message{
  max-width: 500px;
  word-wrap: break-word !important; 
}
.darktheme #cke_editor1, .darktheme #cke_editor2, .darktheme #cke_editor3,
.darktheme #cke_editor4, .darktheme #cke_editor5, .darktheme #cke_editor6{
  -webkit-filter: invert(1);
          filter: invert(1);
}

@media(max-width:575px){
  .btn-info {
    font-size: 10px !important;
    font-weight: normal !important;
    padding: 5px !important;
  }
}
.cust-chat {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 200px);
}
.writetxt {
  margin-top: auto;
  display: flex;
}

.writetxt button {
  background: transparent;
  color: #fff;
  border:1px solid #005eb8;
  border:1px solid var(--themeclr);
  margin-left: 5px;
  padding: 10px;
  border-radius: 5px;
}
.cust-chat .msg > div ,.cust-chat .reply > div{
  border: 1px solid #005eb8;
  border: 1px solid var(--themeclr);
  padding: 8px;
  border-radius: 6px;
}
.cust-chat .msg  ,.cust-chat .reply {
  max-width: 600px;
  width: 100%;
}
.cust-chat .reply{
  margin-left: auto;
}
.cust-chat .reply > div {
  margin-left: auto;
}
.chats {
  height: 100%;
  overflow-y: auto;
  scrollbar-width: none;
}
.chats::-webkit-scrollbar{
  display: none !important;
 
}
.f-12{
  font-size: 12px;
}
.img {
  width: 40px;
  aspect-ratio: 1/1;
  object-fit: cover;
  border-radius: 50%;
}
.gap-2 {
  grid-gap: 10px;
  gap: 10px;
}

.choose_file_label
{
  background-color: transparent !important;
    color: #fff !important;
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #005eb8 !important;
    border-radius: 0.25rem;
    min-height: 52px !important;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    display: flex;
    align-items: center;
    font-size: 1rem !important;
}
body {
    overflow-x: hidden;
    background-color: #0a0a0a !important;
    color:#fff !important;
  font-size: 15px !important;
}

#sidebar-wrapper {
    min-height: 100vh;
    margin-left: -15rem;
    transition: margin .25s ease-out;
}

#sidebar-wrapper .sidebar-heading {
    padding: 0.875rem 1.25rem;
    font-size: 1.2rem;
}
.img-fluid.proofThumb {
    max-height: 135px;
    border-radius: 5px;
    display: block;
    margin: 15px 0 0;
}


#sidebar-wrapper .list-group {
    width: 15rem;
}

#page-content-wrapper {
    min-width: 100vw;
}

#wrapper.toggled #sidebar-wrapper {
    margin-left: 0;
}
#menu-toggle {
    color: #fff;
    border: 2px solid #fff;
    padding: 5px 7px;
    margin: 0 0px 0 0;
    line-height: 0;
    position: fixed;
    top: 15px;
    z-index: 9;
    left: 15px;
}
.navbar.adminNav{
    position: relative;
    display: flex;
    align-items: center;
    padding: 10px 15px 10px 65px;
}

@media (min-width: 768px) {
    #sidebar-wrapper {
        margin-left: 0;
    }

    #page-content-wrapper {
        min-width: 0;
        width: 100%;
        margin-top: 1%;
    }

    #wrapper.toggled #sidebar-wrapper {
        margin-left: -15rem;
    }
    .adminNav .dropdown-menu a{
        display: block;
        color: #000;
        padding:8px 10px;
    }
}

#as-react-datatable-container select {
    width: 70px !important;
}

.container input {
     position: static !important;
     opacity: 1 !important;
     cursor: pointer !important;
     height: auto !important;
     width: 100% !important;
}

.container [type=checkbox] {
    position: absolute !important;
    opacity: 0 !important;
    cursor: pointer !important;
    height: 0 !important;
    width: 0 !important;
}

.chip-body{
    height: 37px !important
}
.multiSelect{
    margin :0 !important;
}

.tableHead h2 {
    font-size: 24px;
    color: #d6b032;
    text-align: left;
    margin: 12px 15px 15px;
    font-weight: 600;
}
@media (max-width: 767px) {
    .adminNav .dropdown-menu{
        background: transparent;
        margin-top: 0;
        padding-top: 0;
        border: 0;
    }
    .adminNav .dropdown-menu a{
        display: block;
        color: #fff;
        padding:10px 0;
    }
    .adminNav .dropdown-menu a:hover{
        color: #d6b032;
        text-decoration: none;
    }
    .adminNav .dropdown-item:hover, .adminNav .dropdown-item:focus{
        background: transparent;
        text-decoration: none;
    }
    .adminNav.navbar-dark .navbar-toggler{
        color: #fff;
        border: 2px solid #fff;
        padding: 5px 7px;
        margin: 0 0px 0 0;
        line-height: 0;
        font-size: inherit;
        outline: none;
    }
    .navbar.adminNav{
    position: relative;
    display: flex;
    align-items: center;
    padding: 10px 15px 10px 65px;
}
}

.border-right
{
  border-color: #0e0e0e !important;
  border-right: 1px solid #0e0e0e !important;
}
.container
{
    padding-left: 15px !important;
}
.navbar-brand
{
    max-width: 150px;
}
.bg-dark.adminNav
{
    background-color: #000 !important;
}
